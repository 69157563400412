import $ from 'jquery'
import 'slick-carousel'

$(document).ready( initInsightsSlider )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=slider', initInsightsSlider)
  window.acf.addAction('render_block_preview/type=featured-insights', initInsightsSlider)
}

function initInsightsSlider(){
  if ($('.js-insights-slider').length) {

    var $slider = $('.js-insights-slider');
    var $progressBar = $slider.next().find('.progress');
    var $progressBarLabel = $progressBar.find($( '.slider__label' ));

    $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;

      $progressBar
        .css('background-size', calc + '% 100%')
        .attr('aria-valuenow', calc );

      $progressBarLabel.text( calc + '% completed' );
    });

    $slider.slick({
      arrows: true,
      dots: false,
      slidesToShow: 3,
      slidesToScroll: 1,
      prevArrow: $('.featured-insights #csNavPrev'),
      nextArrow: $('.featured-insights #csNavNext'),
      // prevArrow: '<button type="button" class="slick-prev"><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none"><path d="M27.7076 16.0418H11.418L18.5346 8.92513C19.1034 8.35638 19.1034 7.42305 18.5346 6.8543C17.9659 6.28555 17.0471 6.28555 16.4784 6.8543L6.86797 16.4647C6.29922 17.0335 6.29922 17.9522 6.86797 18.521L16.4784 28.1314C17.0471 28.7001 17.9659 28.7001 18.5346 28.1314C19.1034 27.5626 19.1034 26.6439 18.5346 26.0751L11.418 18.9585H27.7076C28.5096 18.9585 29.1659 18.3022 29.1659 17.5001C29.1659 16.698 28.5096 16.0418 27.7076 16.0418Z" fill="#E8E2D5"/></svg></button>',
      // nextArrow: '<button type="button" class="slick-next"><svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35" fill="none"><path d="M7.29036 18.9585H23.5799L16.4633 26.0751C15.8945 26.6439 15.8945 27.5772 16.4633 28.146C17.032 28.7147 17.9508 28.7147 18.5195 28.146L28.1299 18.5355C28.6987 17.9668 28.6987 17.048 28.1299 16.4793L18.5341 6.8543C17.9654 6.28555 17.0466 6.28555 16.4779 6.8543C15.9091 7.42305 15.9091 8.3418 16.4779 8.91055L23.5799 16.0418H7.29036C6.48828 16.0418 5.83203 16.698 5.83203 17.5001C5.83203 18.3022 6.48828 18.9585 7.29036 18.9585Z" fill="#E8E2D5"/></svg></button>',
      responsive: [
        {
          breakpoint: 1180,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    });
  }
}
