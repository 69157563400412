import $ from 'jquery'
import 'slick-carousel'

$(document).ready( initCaseStudiesSlider )
if (window.acf) {
  window.acf.addAction('render_block_preview/type=case-studies', initCaseStudiesSlider)
  window.acf.addAction('render_block_preview/type=bcorp-slider', initCaseStudiesSlider)
  window.acf.addAction('render_block_preview/type=book-slider', initCaseStudiesSlider)
}

function initCaseStudiesSlider(){
  if ($('.js-slider--casestudies').length) {
    var $slider = $('.js-slider--casestudies');
    var $progressBar = $slider.parent().next().find('.progress');
    var $progressBarLabel = $progressBar.find($( '.slider__label' ));
    var $slidesToShow = $slider.data('slides') ? $slider.data('slides') : 3;

    $slider.on("init", function (event, slick) {
      var numSlides = slick.slideCount;
      if (numSlides < 3) $slider.find('.slick-track').css('marginLeft', '-15px');
    });

    $slider.on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      var calc = ( (nextSlide) / (slick.slideCount-1) ) * 100;

      $progressBar
        .css('background-size', calc + '% 100%')
        .attr('aria-valuenow', calc );

      $progressBarLabel.text( calc + '% completed' );
    });

    $slider.slick({
      arrows: true,
      dots: false,
      //centerMode: true,
      slidesToShow: $slidesToShow,
      slidesToScroll: 1,
      prevArrow: $('.slider--case-studies__wrap').next().find('#csNavPrev'),
      nextArrow: $('.slider--case-studies__wrap').next().find('#csNavNext'),
      responsive: [
        {
          breakpoint: 1180,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 700,
          settings: {
            slidesToShow: 1,
          }
        }
      ]
    });

    console.log($slider)
  }
}


