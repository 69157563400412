import $ from 'jquery'

const $doc = $(document)

function copyEmail(e) {
  const link = e.target.href
  if( link[link.length -1] == "#") {
    e.preventDefault()
    if (navigator.clipboard) {
      const email = $(e.target).text()
      navigator.clipboard.writeText(email)
      .then(() => {
        if(!$(e.target).next().length) {
          $(e.target).parent().append('<div class="confirmation-message">Email copied!</div>')
        }
      })
    }
  }
}

$doc.on('click', '.js-form-email', copyEmail )
