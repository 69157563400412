import $ from 'jquery'
import 'slick-carousel'

$(document).ready( initPartnersCarousel )

function initPartnersCarousel(){
  if ($('.js-partners-carousel').length) {
    $('.js-partners-carousel').slick({
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      variableWidth: true,
      draggable: false,
      pauseOnFocus: false,
      pauseOnHover: false
    }).on("beforeChange", function(e, slick) {
      var width = $('.js-partners-carousel .slick-current').outerWidth();
      var speed = (width*3000)/200;
      $('.js-partners-carousel').slick("setOption", "speed", speed);
    });

    $('.js-partners__pause i.pause').on('click', function(i) {
      $(this).hide()
      $(this).next('i.play').show()
      $('.js-partners-carousel').slick('pause')
    })
    
    $('.js-partners__pause i.play').on('click', function(i) {
      $(this).hide()
      $(this).prev('i.pause').show()
      $('.js-partners-carousel').slick('play')
    })
  }
}
