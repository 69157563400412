import $ from 'jquery'
import 'slick-carousel'

$(document).ready( initCTASlider )
$(document).ready( initCTAcontent )

function initCTASlider(){

  if ($('.js-cta').length) {
    $('.js-cta').slick({
      arrows: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 0,
      cssEase: 'linear',
      variableWidth: true,
      centerMode: true,
      draggable: false,
      pauseOnFocus: false,
      pauseOnHover: false
    }).on("beforeChange", function(e, slick) {
      var width = $('.js-cta .slick-current').outerWidth();
      var speed = (width*3000)/200;
      $('.js-cta').slick("setOption", "speed", speed);
    });

    $('.js-cta__pause i.pause').on('click', function(i) {
      $(this).hide()
      $(this).next('i.play').show()
      $('.js-cta').slick('pause')
    })
    
    $('.js-cta__pause i.play').on('click', function(i) {
      $(this).hide()
      $(this).prev('i.pause').show()
      $('.js-cta').slick('play')
    })
  }

}

function initCTAcontent() {

  if ($('.cta__content').length) {
    $('.cta__content').each(function() {
      const lineWidth = $(this).find('.cta__line').eq(0).innerWidth() + 40;
      $(this).css("maxWidth", lineWidth+"px");
    });
  }

}

$(window).on('resize', function() {
  $('.cta__content').each(function() {
    $(this).css("maxWidth", "");
  })
  if ($(window).width() > 700) { 
    initCTAcontent();
  }
});
