import $ from 'jquery'

const $doc = $(document)

function ajaxLoadBlogPosts(page = 1, cat = '') {
  const type = $('body').find('#ajaxPosts').data('type') ? $('body').find('#ajaxPosts').data('type') : 'post';
  const data = {
    page: page,
    cat: cat,
    type: type,
    action: 'ajax_blog_filter'
  };
  console.log(data)

  $.ajax({
    url: php_vars.ajax_url,
    type: "POST",
    dataType: 'html',
    data,
    beforeSend: function () {
      $("html").animate({
          scrollTop: $("#ajaxPosts").offset().top - 200
        }, 400
      );
      $('body').find('#ajaxPosts').html('<div class="loader"><svg version="1.1" id="L7" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve"><path fill="#CC5C28" d="M31.6,3.5C5.9,13.6-6.6,42.7,3.5,68.4c10.1,25.7,39.2,38.3,64.9,28.1l-3.1-7.9c-21.3,8.4-45.4-2-53.8-23.3 c-8.4-21.3,2-45.4,23.3-53.8L31.6,3.5z"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="2s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path><path fill="#CC5C28" d="M42.3,39.6c5.7-4.3,13.9-3.1,18.1,2.7c4.3,5.7,3.1,13.9-2.7,18.1l4.1,5.5c8.8-6.5,10.6-19,4.1-27.7 c-6.5-8.8-19-10.6-27.7-4.1L42.3,39.6z"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="1s" from="0 50 50" to="-360 50 50" repeatCount="indefinite" /></path><path fill="#CC5C28" d="M82,35.7C74.1,18,53.4,10.1,35.7,18S10.1,46.6,18,64.3l7.6-3.4c-6-13.5,0-29.3,13.5-35.3s29.3,0,35.3,13.5 L82,35.7z"><animateTransform attributeName="transform" attributeType="XML" type="rotate" dur="2s" from="0 50 50" to="360 50 50" repeatCount="indefinite" /></path></svg></div>');
    },
    success: function (htmlResponse) {
      if (htmlResponse == "" || htmlResponse == null) {
        $('body').find('#ajaxPosts').html('<p class="no-results">No results found, please try a different combination of filters.</p>');
      }
      else {
        $('body').find('#ajaxPosts').html(htmlResponse);
      }
    },
    error: function () {
      $('body').find('#ajaxPosts').html('<p class="no-results">Sorry, no results found.</p>');
    },
    complete: function () {
      //$('body').find('#ajaxPosts').css('opacity', 1);
    }
  });
}

function filterBlogPosts() {
  let page = 2;
  let cat;
  if ('URLSearchParams' in window) {
    var searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('cat')) {
      cat = searchParams.get('cat');
      $('#'+cat).prop('checked', true);
    }
    if (searchParams.has('cat')) {
      ajaxLoadBlogPosts(1, cat);
    }
  }
  $('.js-post-filter').on('change', function (event) {
    cat = $("#catFilter input:checkbox:checked").map(function () {
      return $(this).val()
    }).get();
    if ( cat.length == 0 ) {
      $('#catFilter').find('.js-filter-count').text('').addClass('hidden')
    } else {
      $('#catFilter').find('.js-filter-count').text(cat.length).removeClass('hidden')
    }
    ajaxLoadBlogPosts(1, cat);
  });
  $doc.on('click', '.js-pagination button', function () {
    page = $(this).data('page');
    ajaxLoadBlogPosts(page, cat);
  });
}

export default function initBlogFunctions(){
  if (($('body').hasClass('blog') || $('body').hasClass('archive')) && !$('body').hasClass('post-type-archive-case-study')) {
    $(document).ready(filterBlogPosts);
  }
}
initBlogFunctions();
