import $ from 'jquery'
import 'slick-carousel'

$(document).ready( initRotatingCarousel )


let mediaQuery = 200;
if ($(window).width() < 500) mediaQuery = 150;

let intervals = [];

function initRotatingCarousel() {
  if ($('.js-rotating-carousel').length) {

    $('.rotating-carousel__highlight').each(createSlider);

    function createSlider(i, elem) {
      const track = $(elem).find('.js-rotating-carousel-word');
      const imageTrack = $(elem).find('.js-rotating-carousel-image');
      const firstSlide = track.find('.slide').eq(0);
      firstSlide.addClass('active');
      const firstSlideWidth = firstSlide.outerWidth();
      track.css('width', firstSlideWidth+"px");
      let firstImage = imageTrack.find('.slide-image').eq(0);

      const interval = setInterval(function() {
        let targetWidth = track.find('.slide.active').next().outerWidth() + mediaQuery;
        const slide = track.find('.slide').eq(0);
        const nextSlide = slide.next();
        let color = nextSlide.attr('data-color');
        $(elem).attr('data-color', color);
        $(elem).animate({
          width: targetWidth+'px'
        }, {
          duration: 200,
          complete: function() {
            slide.removeClass('active').appendTo(track);
            nextSlide.addClass('active');
          }
        });
        firstImage = firstImage.fadeOut(500, function() {
          $(this).appendTo(imageTrack);
        })
        .next()
        .fadeIn(500);
        
      }, 2000);

      intervals.push(interval)
    }

    $('.js-rotating-carousel__pause i.pause').on('click', function(i) {
      $(this).hide();
      $(this).next('i.play').show()
      clearInterval(intervals[0]);
      clearInterval(intervals[1]);
      intervals = [];
    })

    $('.js-rotating-carousel__pause i.play').on('click', function(i) {
      $(this).hide();
      $(this).prev('i.pause').show()
      $('.rotating-carousel__highlight').each(createSlider);
    })
  }

}
